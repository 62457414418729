import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { commonMessages } from "@temp/intl";
import { useAuth, useCart } from "@saleor/sdk";
import { Link } from "react-router-dom";
import { MenuDropdown, OverlayContext, OverlayTheme, OverlayType } from "..";
import * as appPaths from "../../app/routes";
import "./scss/index.scss";
import Slider from "react-slick";

var MainMenu = function MainMenu() {
  var _useAuth = useAuth(),
      user = _useAuth.user,
      signOut = _useAuth.signOut;

  var _useCart = useCart(),
      items = _useCart.items;

  var handleSignOut = function handleSignOut() {
    signOut();
  };

  var cartItemsQuantity = items && items.reduce(function (prevVal, currVal) {
    return prevVal + currVal.quantity;
  }, 0) || 0; // AJOUTS

  var overlayContext = useContext(OverlayContext);
  var activeDropdown;
  useEffect(function () {
    if (activeDropdown) {
      overlayContext.show(OverlayType.mainMenuNav, OverlayTheme.modal);
    } else {
      overlayContext.hide();
    }
  }, [activeDropdown]);

  var _useState = useState("header-menu-toggle"),
      _useState2 = _slicedToArray(_useState, 2),
      classMenu = _useState2[0],
      setMenu = _useState2[1];

  var _useState3 = useState("site-layout--default"),
      _useState4 = _slicedToArray(_useState3, 2),
      classSiteWrapper = _useState4[0],
      setSiteWrapper = _useState4[1];

  var _useState5 = useState("header-cart-toggle"),
      _useState6 = _slicedToArray(_useState5, 2),
      classCartToggle = _useState6[0],
      setCartToggle = _useState6[1];

  var _useState7 = useState("header-search-toggle"),
      _useState8 = _slicedToArray(_useState7, 2),
      classSearchToggle = _useState8[0],
      setSearchToggle = _useState8[1];

  var _useState9 = useState("header-social-toggle d-none d-md-block"),
      _useState10 = _slicedToArray(_useState9, 2),
      classSocialToggle = _useState10[0],
      setSocialToggle = _useState10[1];

  var classTopBarToggle = "header-top-bar-toggle d-md-none hide"; // const [class, set] = useState("");

  var _useState11 = useState(false),
      _useState12 = _slicedToArray(_useState11, 2),
      overlayOpen = _useState12[0],
      setOverlayOpen = _useState12[1];

  var _useState13 = useState(null),
      _useState14 = _slicedToArray(_useState13, 2),
      overlayType = _useState14[0],
      setOverlayType = _useState14[1];

  var _useState15 = useState("dl-menu dl-menuopen"),
      _useState16 = _slicedToArray(_useState15, 2),
      classVueSubMenu = _useState16[0],
      setVueSubMenu = _useState16[1];

  var _useState17 = useState("has-children"),
      _useState18 = _slicedToArray(_useState17, 2),
      classGPU = _useState18[0],
      setGPU = _useState18[1];

  var _useState19 = useState("has-children"),
      _useState20 = _slicedToArray(_useState19, 2),
      classASIC = _useState20[0],
      setASIC = _useState20[1]; // INTERACTION DESKTOP


  var onClickGPU = function onClickGPU() {
    if (classVueSubMenu === "dl-menu dl-menuopen") {
      setVueSubMenu("dl-menu dl-menuopen dl-submenu dl-subview dl-animate-in-1");
      setGPU("has-children dl-subviewopen dl-animate-in-1");
      setTimeout(function () {
        setVueSubMenu("dl-menu dl-menuopen dl-subview");
        setGPU("has-children dl-subviewopen");
      }, 400);
    } else {
      setGPU("has-children dl-animate-out-1");
      setVueSubMenu("dl-menu dl-menuopen dl-animate-in-2");
      setTimeout(function () {
        setVueSubMenu("dl-menu dl-menuopen");
        setGPU("has-children");
      }, 300);
    }
  };

  var onClickASIC = function onClickASIC() {
    if (classVueSubMenu === "dl-menu dl-menuopen") {
      setVueSubMenu("dl-menu dl-menuopen dl-submenu dl-subview dl-animate-in-1");
      setASIC("has-children dl-subviewopen dl-animate-in-1");
      setTimeout(function () {
        setVueSubMenu("dl-menu dl-menuopen dl-subview");
        setASIC("has-children dl-subviewopen");
      }, 400);
    } else {
      setASIC("has-children dl-animate-out-1");
      setVueSubMenu("dl-menu dl-menuopen dl-animate-in-2");
      setTimeout(function () {
        setVueSubMenu("dl-menu dl-menuopen");
        setASIC("has-children");
      }, 300);
    }
  };

  var toggleClassMenu = function toggleClassMenu() {
    if (classSiteWrapper === "site-layout--default") {
      setSiteWrapper("site-layout--default site-wrapper--has-menu-overlay");
      setMenu("header-menu-toggle toggled");
      setSocialToggle("header-social-toggle d-none d-md-block hide");
      setCartToggle("header-cart-toggle hide");
      setSearchToggle("header-search-toggle hide");
    } else {
      setSiteWrapper("site-layout--default");
      setMenu("header-menu-toggle");
      setSocialToggle("header-social-toggle d-none d-md-block");
      setCartToggle("header-cart-toggle");
      setSearchToggle("header-search-toggle");
    }
  }; // INTERACTION MOBILE


  var _useState21 = useState("mobile-bar-item__header collapsed"),
      _useState22 = _slicedToArray(_useState21, 2),
      classGPUMobile = _useState22[0],
      setGPUMobile = _useState22[1];

  var _useState23 = useState("mobile-bar-item__header collapsed"),
      _useState24 = _slicedToArray(_useState23, 2),
      classASICMobile = _useState24[0],
      setASICMobile = _useState24[1];

  var onClickGPUMobile = function onClickGPUMobile() {
    if (classGPUMobile === "mobile-bar-item__header collapsed") {
      setGPUMobile("mobile-bar-item__header");
    } else {
      setGPUMobile("mobile-bar-item__header collapsed");
    }
  };

  var onClickASICMobile = function onClickASICMobile() {
    if (classASICMobile === "mobile-bar-item__header collapsed") {
      setASICMobile("mobile-bar-item__header");
    } else {
      setASICMobile("mobile-bar-item__header collapsed");
    }
  }; // SLIDER SETTING


  var settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    centerMode: true
  };
  return React.createElement(OverlayContext.Consumer, null, function (overlayContext) {
    return React.createElement(React.Fragment, null, React.createElement("div", {
      className: classSiteWrapper
    }, React.createElement("header", {
      id: "header",
      className: "site-header site-header--bottom"
    }, React.createElement("div", {
      className: "header-logo header-logo--img"
    }, React.createElement(Link, {
      to: appPaths.baseUrl
    }, React.createElement("img", {
      src: "/assets/img/mm-logo-green.svg",
      srcSet: "/assets/img/logo@2x.png",
      alt: "mining-market"
    }))), React.createElement("nav", {
      className: "main-nav"
    }, React.createElement("ul", {
      className: "main-nav__list"
    }, React.createElement("li", {
      className: ""
    }, React.createElement(Link, {
      to: appPaths.baseUrl
    }, "Accueil")), React.createElement("li", {
      className: ""
    }, React.createElement("a", {
      href: "javascript:void(0);"
    }, "GPU Mining"), React.createElement("ul", {
      className: "main-nav__sub"
    }, React.createElement("li", {
      className: ""
    }, React.createElement(Link, {
      to: appPaths.categoryGpuUrl
    }, "GPUs")), React.createElement("li", {
      className: ""
    }, React.createElement(Link, {
      to: appPaths.categoryRigPartsUrl
    }, "RIG Parts")), React.createElement("li", {
      className: ""
    }, React.createElement("a", {
      href: appPaths.categoryBuiltRigsUrl
    }, "Built Rigs \xA0", React.createElement("span", {
      className: "badge badge-success"
    }, "New")))), React.createElement("span", {
      className: "main-nav__toggle"
    }, "\xA0")), React.createElement("li", {
      className: ""
    }, React.createElement("a", {
      href: "javascript:void(0);"
    }, "ASIC Mining"), React.createElement("ul", {
      className: "main-nav__sub"
    }, React.createElement("li", {
      className: ""
    }, React.createElement(Link, {
      to: appPaths.categoryAsicUrl
    }, "Miners")), React.createElement("li", {
      className: ""
    }, React.createElement(Link, {
      to: appPaths.categoryAsicSparePartsUrl
    }, "Spare Parts"))), React.createElement("span", {
      className: "main-nav__toggle"
    }, "\xA0")), React.createElement("li", {
      className: ""
    }, React.createElement("a", {
      href: appPaths.hostedUrl
    }, "Hosted Mining")), React.createElement("li", {
      className: ""
    }, React.createElement("a", {
      href: appPaths.dataUrl
    }, "Data Tools")))), React.createElement("div", {
      className: "header-actions"
    }, React.createElement("div", {
      className: classTopBarToggle
    }, React.createElement("svg", {
      role: "img",
      className: "df-icon df-icon--close"
    }, React.createElement("use", {
      xlinkHref: "/assets/img/mm-sprite.svg#close"
    }))), React.createElement("div", {
      className: classSocialToggle
    }, React.createElement("svg", {
      role: "img",
      className: "df-icon df-icon--thumb-up"
    }, React.createElement("use", {
      xlinkHref: "/assets/img/mm-sprite.svg#thumb-up"
    })), React.createElement("span", {
      className: "header-social-toggle__plus"
    }, "\xA0"), React.createElement("ul", {
      className: "social-menu social-menu--header"
    }, React.createElement("li", null, React.createElement("a", {
      href: "https://discord.gg/GShtcRNeJE"
    }, React.createElement("span", {
      className: "link-subtitle"
    }, "Discord"), "Mining Market")), React.createElement("li", null, React.createElement("a", {
      href: "https://twitter.com/MiningMarket_21"
    }, React.createElement("span", {
      className: "link-subtitle"
    }, "Twitter"), "Mining Market")))), React.createElement("div", {
      onClick: function onClick() {
        if (!overlayOpen || overlayType !== OverlayType.cart) {
          overlayContext.show(OverlayType.cart, OverlayTheme.right);
          setOverlayType(OverlayType.cart);
        } else {
          overlayContext.hide();
          setOverlayType(null);
        }

        setOverlayOpen(!overlayOpen);
      },
      className: classCartToggle
    }, React.createElement("svg", {
      role: "img",
      className: "df-icon df-icon--bag"
    }, React.createElement("use", {
      xlinkHref: "/assets/img/mm-sprite.svg#bag"
    })), React.createElement("svg", {
      role: "img",
      className: "df-icon df-icon--close"
    }, React.createElement("use", {
      xlinkHref: "/assets/img/mm-sprite.svg#close"
    })), React.createElement("span", {
      className: "header-cart-toggle__items-count"
    }, cartItemsQuantity > 0 ? cartItemsQuantity : null)), React.createElement("div", {
      onClick: function onClick() {
        if (!overlayOpen || overlayType !== OverlayType.search) {
          overlayContext.show(OverlayType.search, OverlayTheme.right);
          setOverlayType(OverlayType.search);
        } else {
          overlayContext.hide();
          setOverlayType(null);
        }

        setOverlayOpen(!overlayOpen);
      },
      className: classSearchToggle
    }, React.createElement("svg", {
      role: "img",
      className: "df-icon df-icon--search"
    }, React.createElement("use", {
      xlinkHref: "/assets/img/mm-sprite.svg#search"
    })), React.createElement("svg", {
      role: "img",
      className: "df-icon df-icon--search-close"
    }, React.createElement("use", {
      xlinkHref: "/assets/img/mm-sprite.svg#search-close"
    }))), React.createElement(React.Fragment, null, user ? React.createElement("div", {
      className: classSocialToggle
    }, React.createElement(MenuDropdown, {
      head: React.createElement(React.Fragment, null, React.createElement("span", {
        className: "header-social-toggle__plus"
      }, "\xA0"), React.createElement("svg", {
        role: "img",
        className: "df-icon df-icon--logout"
      }, React.createElement("use", {
        xlinkHref: "/assets/img/mm-sprite.svg#logout"
      }))),
      content: React.createElement("ul", {
        className: "social-menu social-menu--header"
      }, React.createElement("li", {
        "data-test": "desktopMenuMyAccountLink"
      }, React.createElement("a", {
        href: appPaths.accountUrl
      }, React.createElement("span", {
        className: "link-subtitle"
      }, "my account"), React.createElement(FormattedMessage, commonMessages.myAccount))), React.createElement("li", {
        "data-test": "desktopMenuOrderHistoryLink"
      }, React.createElement("a", {
        href: appPaths.orderHistoryUrl
      }, React.createElement("span", {
        className: "link-subtitle"
      }, "order history"), React.createElement(FormattedMessage, commonMessages.orderHistory))), React.createElement("li", {
        "data-test": "desktopMenuAddressBookLink"
      }, React.createElement("a", {
        href: appPaths.addressBookUrl
      }, React.createElement("span", {
        className: "link-subtitle"
      }, "address book"), React.createElement(FormattedMessage, commonMessages.addressBook))), React.createElement("li", {
        onClick: handleSignOut,
        "data-test": "desktopMenuLogoutLink"
      }, React.createElement("a", {
        href: "javascript:void(0);"
      }, React.createElement("span", {
        className: "link-subtitle"
      }, "log out"), React.createElement(FormattedMessage, commonMessages.logOut))))
    })) : React.createElement("div", {
      className: classSocialToggle,
      onClick: function onClick() {
        if (!overlayOpen || overlayType !== OverlayType.login) {
          overlayContext.show(OverlayType.login, OverlayTheme.right);
          setOverlayType(OverlayType.login);
        } else {
          overlayContext.hide();
          setOverlayType(null);
        }
      }
    }, React.createElement("svg", {
      role: "img",
      className: "df-icon df-icon--account"
    }, React.createElement("use", {
      xlinkHref: "/assets/img/mm-sprite.svg#account"
    })))), React.createElement("div", {
      className: classMenu,
      onClick: toggleClassMenu
    }, React.createElement("div", {
      className: "header-menu-toggle__inner"
    }, React.createElement("span", null, "\xA0"), React.createElement("span", null, "\xA0"), React.createElement("span", null, "\xA0"))))), React.createElement("div", {
      className: "site-overlay"
    }), React.createElement("div", {
      className: "menu-panel"
    }, React.createElement("ul", {
      className: "menu-panel__mobile-bar list-unstyled d-md-none"
    }, React.createElement("li", {
      className: "mobile-bar-item"
    }, React.createElement("a", {
      href: "javascript:void(0);",
      className: classGPUMobile,
      "data-toggle": "collapse",
      onClick: function onClick() {
        return onClickGPUMobile();
      },
      role: "button",
      "aria-expanded": classGPUMobile === "mobile-bar-item__header",
      "aria-controls": "mobile_collapse_1"
    }, "GPU Mining ", React.createElement("span", {
      className: "main-nav__toggle"
    }, "\xA0")), React.createElement("div", {
      id: "mobile_collapse_1",
      className: "collapse mobile-bar-item__body ".concat(classGPUMobile === "mobile-bar-item__header" ? "collapse show" : "")
    }, React.createElement("nav", {
      className: "mobile-nav"
    }, React.createElement("ul", {
      className: "mobile-nav__list"
    }, React.createElement("li", {
      className: ""
    }, React.createElement(Link, {
      to: appPaths.categoryGpuUrl
    }, "GPUs"), React.createElement(Link, {
      to: appPaths.categoryRigPartsUrl
    }, "Rig Parts"), React.createElement(Link, {
      to: appPaths.categoryBuiltRigsUrl
    }, "Built Rigs")))))), React.createElement("li", {
      className: "mobile-bar-item"
    }, React.createElement("a", {
      className: classASICMobile,
      "data-toggle": "collapse",
      onClick: function onClick() {
        return onClickASICMobile();
      },
      href: "javascript:void(0);",
      role: "button",
      "aria-expanded": "false",
      "aria-controls": "mobile_collapse_2"
    }, "ASIC Mining ", React.createElement("span", {
      className: "main-nav__toggle"
    }, "\xA0")), React.createElement("div", {
      id: "mobile_collapse_2",
      className: "collapse mobile-bar-item__body ".concat(classASICMobile === "mobile-bar-item__header" ? "collapse show" : "")
    }, React.createElement("nav", {
      className: "mobile-nav"
    }, React.createElement("ul", {
      className: "mobile-nav__list"
    }, React.createElement("li", {
      className: ""
    }, React.createElement(Link, {
      to: appPaths.categoryAsicUrl
    }, "Miners"), React.createElement(Link, {
      to: appPaths.categoryAsicSparePartsUrl
    }, "Spare Parts")))))), React.createElement("li", {
      className: "mobile-bar-item"
    }, React.createElement("a", {
      className: "mobile-bar-item__header",
      href: "hosting"
    }, "Hosted Mining")), React.createElement("li", {
      className: "mobile-bar-item"
    }, React.createElement("a", {
      className: "mobile-bar-item__header",
      href: "data"
    }, "DATA Infos")), React.createElement("li", {
      className: "mobile-bar-item"
    }, React.createElement("a", {
      className: "mobile-bar-item__header",
      href: "contact"
    }, "Contact")), React.createElement("li", {
      className: "mobile-bar-item"
    }, React.createElement("a", {
      className: "mobile-bar-item__header",
      href: appPaths.accountUrl
    }, "Mon compte"))), React.createElement("div", {
      className: "menu-panel__content d-none d-md-flex"
    }, React.createElement("div", {
      className: "menu-panel__navigation"
    }, React.createElement("div", {
      id: "dl-menu",
      className: "dl-menuwrapper"
    }, React.createElement("ul", {
      className: classVueSubMenu
    }, React.createElement("li", {
      className: ""
    }, React.createElement("a", {
      href: appPaths.baseUrl
    }, "Accueil")), React.createElement("li", {
      className: classGPU
    }, React.createElement("a", {
      href: "javascript:void(0);",
      style: {
        color: "white"
      },
      onClick: function onClick() {
        return onClickGPU();
      }
    }, "GPU Mining"), React.createElement("ul", {
      className: "dl-submenu"
    }, React.createElement("li", {
      className: "dl-back"
    }, React.createElement("a", {
      href: "javascript:void(0);",
      style: {
        color: "white"
      },
      onClick: function onClick() {
        return onClickGPU();
      }
    }, "Retour")), React.createElement("li", {
      className: "active"
    }, React.createElement("a", {
      href: "javascript:void(0);"
    }, "GPUS")), React.createElement("li", {
      className: ""
    }, React.createElement(Link, {
      to: appPaths.categoryRigPartsUrl
    }, "RIG Parts")), React.createElement("li", {
      className: ""
    }, React.createElement("a", {
      href: "/category/build-rigs/1/"
    }, "Built Rig", React.createElement("span", {
      className: "badge badge-success"
    }, "New \xA0")))), React.createElement("span", {
      className: "dl-toggle"
    }, "\xA0")), React.createElement("li", {
      className: classASIC
    }, React.createElement("a", {
      href: "javascript:void(0);",
      style: {
        color: "white"
      },
      onClick: function onClick() {
        return onClickASIC();
      }
    }, "ASIC Mining"), React.createElement("ul", {
      className: "dl-submenu"
    }, React.createElement("li", {
      className: "dl-back"
    }, React.createElement("a", {
      href: "javascript:void(0);",
      onClick: function onClick() {
        return onClickASIC();
      }
    }, "Retour")), React.createElement("li", {
      className: "active"
    }, React.createElement(Link, {
      to: appPaths.categoryAsicUrl
    }, "Miners")), React.createElement("li", {
      className: ""
    }, React.createElement(Link, {
      to: appPaths.categoryAsicSparePartsUrl
    }, "Spare Parts"))), React.createElement("span", {
      className: "dl-toggle"
    }, "\xA0")), React.createElement("li", {
      className: ""
    }, React.createElement(Link, {
      to: appPaths.hostedUrl
    }, "Hosted Mining")), React.createElement("li", {
      className: ""
    }, React.createElement(Link, {
      to: appPaths.dataUrl
    }, "DATA Infos"))))), React.createElement("div", {
      className: "menu-panel__widget-area"
    }, React.createElement("div", {
      className: "row"
    }, React.createElement("div", {
      className: "col-md-12 col-lg-6 col-xl-5"
    }, React.createElement("section", {
      className: "widget widget-text"
    }, React.createElement("h5", {
      className: "widget__title"
    }, "Partenariats"), React.createElement("div", {
      className: "widget__content"
    }, React.createElement("p", null, "Devenez partenaire de Mining Market."), React.createElement("div", {
      className: "info-box"
    }, React.createElement("div", {
      className: "info-box__label"
    }, "Devenez partenaire"), React.createElement("div", {
      className: "info-box__content"
    }, React.createElement("a", {
      href: "mailto:partner@miningmarket.fr"
    }, "partners", React.createElement("span", {
      className: "color-primary"
    }, "@"), "miningmarket.fr")))))), React.createElement("div", {
      className: "col-md-12 col-lg-6 col-xl-5 offset-xl-2 mt-5 mt-lg-0"
    }, React.createElement("section", {
      className: "widget widget-contact-info"
    }, React.createElement("h5", {
      className: "widget__title"
    }, "Contact Info"), React.createElement("div", {
      className: "widget__content"
    }, React.createElement("p", null, "Contactez nous pour plus d'infos."), React.createElement("div", {
      className: "info-box"
    }, React.createElement("div", {
      className: "info-box__label"
    }, "Demande d'infos"), React.createElement("div", {
      className: "info-box__content"
    }, React.createElement("a", {
      href: "mailto:#"
    }, "shop", React.createElement("span", {
      className: "color-primary"
    }, "@"), "miningmarket.fr"))), React.createElement("ul", {
      className: "social-menu social-menu--default"
    }, React.createElement("li", null, React.createElement("a", {
      href: "https://twitter.com/MiningMarket_21"
    })), React.createElement("li", null, React.createElement("a", {
      href: "https://discord.gg/GShtcRNeJE"
    }))))))), React.createElement("div", {
      className: "row"
    }, React.createElement("div", {
      className: "col-md-12"
    }, React.createElement("section", {
      className: "widget widget-partners-carousel"
    }, React.createElement("h5", {
      className: "widget__title"
    }, "Nos Marques"), React.createElement("div", {
      className: "widget__content"
    }, React.createElement("ul", null, React.createElement(Slider, settings, React.createElement("li", null, React.createElement("img", {
      src: "/assets/img/brands/brand-amd.png",
      className: "selector",
      alt: "amd"
    })), React.createElement("li", null, React.createElement("img", {
      src: "/assets/img/brands/brand-bitmain.png",
      className: "selector",
      alt: "bitmain"
    })), React.createElement("li", null, React.createElement("img", {
      src: "/assets/img/brands/brand-canaan.png",
      className: "selector",
      alt: "canaan"
    })), React.createElement("li", null, React.createElement("img", {
      src: "/assets/img/brands/brand-goldshell.png",
      className: "selector",
      alt: "goldshell"
    })), React.createElement("li", null, React.createElement("img", {
      src: "/assets/img/brands/brand-innosilicon.png",
      className: "selector",
      alt: "innosilicon"
    })))))))))))));
  });
};

export default MainMenu;