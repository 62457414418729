import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useEffect } from "react";
import AddToCartSection from "@components/organisms/AddToCartSection";
import { Breadcrumbs, OverlayContext, OverlayTheme, OverlayType } from "../../components";
import { generateCategoryUrl, generateProductUrl } from "../../core/utils";
import Slider from "react-slick";
import { getSvg } from "./svg";
import { RichTextContent } from "@components/atoms"; // Fonction

function sliceIntoChunks(arr, chunkSize) {
  var res = [];

  for (var i = 0; i < arr.length; i += chunkSize) {
    var chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }

  return res;
}

var populateBreadcrumbs = function populateBreadcrumbs(product) {
  return [{
    link: generateCategoryUrl(product.category.id, product.category.name),
    value: product.category.name
  }, {
    link: generateProductUrl(product.id, product.name),
    value: product.name
  }];
};

var Page = function Page(_ref) {
  var _product$category, _product$category2, _product$pricing2, _product$pricing2$pri, _product$pricing2$pri2, _product$pricing2$pri3, _product$pricing2$pri4;

  var add = _ref.add,
      product = _ref.product,
      items = _ref.items,
      queryAttributes = _ref.queryAttributes,
      onAttributeChangeHandler = _ref.onAttributeChangeHandler;
  var overlayContext = React.useContext(OverlayContext);

  var _React$useState = React.useState(""),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      variantId = _React$useState2[0],
      setVariantId = _React$useState2[1];

  var _React$useState3 = React.useState(),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      conversion = _React$useState4[0],
      setConversion = _React$useState4[1];

  var _React$useState5 = React.useState(),
      _React$useState6 = _slicedToArray(_React$useState5, 2),
      dataEarn = _React$useState6[0],
      setDataEarn = _React$useState6[1];

  useEffect(function () {
    Promise.all([// INFO
    fetch("https://datas.miningmarket.fr/https://whattomine.com/coins/1-btc-sha-256/history?cost=0.0&fee=0.0&format=json&hr=1000.0&p=0").then(function (res) {
      return res.json();
    }).then(function (res) {
      try {
        var _res$2$data;

        var data = (_res$2$data = res[2].data) === null || _res$2$data === void 0 ? void 0 : _res$2$data.map(function (x) {
          return parseFloat(x.y);
        });
        var mean = (data === null || data === void 0 ? void 0 : data.reduce(function (a, b) {
          return a + b;
        }, 0)) / (data === null || data === void 0 ? void 0 : data.length); // @ts-ignore

        setDataEarn(mean);
      } catch (_unused) {
        setDataEarn(null);
      }
    }).catch(function (_) {
      return setDataEarn(null);
    }), fetch("https://api.coingecko.com/api/v3/coins/tether").then(function (res) {
      return res.json();
    }).then(function (res) {
      var _res$market_data, _res$market_data$curr;

      return setConversion(res === null || res === void 0 ? void 0 : (_res$market_data = res.market_data) === null || _res$market_data === void 0 ? void 0 : (_res$market_data$curr = _res$market_data.current_price) === null || _res$market_data$curr === void 0 ? void 0 : _res$market_data$curr.eur);
    }).catch(function (_) {
      return setConversion(null);
    })]);
  }, []);

  var getImages = function getImages() {
    if (product.variants && variantId) {
      var variant = product.variants.find(function (variant) {
        return variant.id === variantId;
      });

      if ((variant === null || variant === void 0 ? void 0 : variant.images.length) > 0) {
        return variant.images;
      }
    }

    return product.images;
  };

  var handleAddToCart = function handleAddToCart(variantId, quantity) {
    add(variantId, quantity);
    overlayContext.show(OverlayType.cart, OverlayTheme.right);
  };

  var addToCartSection = React.createElement(AddToCartSection, {
    items: items,
    productId: product.id,
    name: product.name,
    productVariants: product.variants,
    productPricing: product.pricing,
    queryAttributes: queryAttributes,
    setVariantId: setVariantId,
    variantId: variantId,
    onAddToCart: handleAddToCart,
    onAttributeChangeHandler: onAttributeChangeHandler,
    isAvailableForPurchase: product.isAvailableForPurchase,
    availableForPurchase: product.availableForPurchase
  });
  var settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    centerMode: true
  };
  var mainAttributes = {
    "ASIC": [// Ligne 1
    [{
      name: "hashrate",
      unite: "TH/s"
    }, {
      name: "puissance",
      unite: "W"
    }, {
      name: "roi",
      unite: "% / mois"
    }], // Ligne 2
    [{
      name: "ventilateurs",
      unite: ""
    }, {
      name: "bruit",
      unite: "dB"
    }, {
      name: "interface",
      unite: ""
    }]]
  };
  var category = product === null || product === void 0 ? void 0 : (_product$category = product.category) === null || _product$category === void 0 ? void 0 : _product$category.name;
  var mainNamesAttributes = {
    "ASIC": ["hashrate", "puissance", "roi", "ventilateurs", "bruit", "interface"]
  };

  var detailsAttributes = function detailsAttributes() {
    if (category) {
      try {
        var res = product.attributes.filter(function (x) {
          return !mainNamesAttributes[category].includes(x.attribute.name);
        });
        return sliceIntoChunks(res, 3);
      } catch (_unused2) {
        return [];
      }
    } else {
      return [];
    }
  };

  var getValueAtrribute = function getValueAtrribute(product, target) {
    if (target === "roi") {
      var _product$pricing, _product$pricing$pric, _product$pricing$pric2, _product$pricing$pric3;

      var hashrate = parseInt(product.attributes.find(function (x) {
        var _x$attribute, _x$attribute$name;

        return (x === null || x === void 0 ? void 0 : (_x$attribute = x.attribute) === null || _x$attribute === void 0 ? void 0 : (_x$attribute$name = _x$attribute.name) === null || _x$attribute$name === void 0 ? void 0 : _x$attribute$name.toLowerCase()) === "hashrate";
      }).values[0].name, 10);
      var price = product === null || product === void 0 ? void 0 : (_product$pricing = product.pricing) === null || _product$pricing === void 0 ? void 0 : (_product$pricing$pric = _product$pricing.priceRange) === null || _product$pricing$pric === void 0 ? void 0 : (_product$pricing$pric2 = _product$pricing$pric.start) === null || _product$pricing$pric2 === void 0 ? void 0 : (_product$pricing$pric3 = _product$pricing$pric2.gross) === null || _product$pricing$pric3 === void 0 ? void 0 : _product$pricing$pric3.amount;
      var roi = dataEarn && conversion ? (dataEarn * hashrate * conversion * 3000 / price).toFixed(2) : "-";
      return roi;
    } else {
      try {
        return product.attributes.find(function (x) {
          var _x$attribute2, _x$attribute2$name;

          return (x === null || x === void 0 ? void 0 : (_x$attribute2 = x.attribute) === null || _x$attribute2 === void 0 ? void 0 : (_x$attribute2$name = _x$attribute2.name) === null || _x$attribute2$name === void 0 ? void 0 : _x$attribute2$name.toLowerCase()) === target;
        }).values[0].name;
      } catch (_unused3) {
        return "-";
      }
    }
  };

  console.log("detailsAttributes", detailsAttributes());
  console.log("product", product);
  return (// <div className="product-page">
    //   <div className="container">
    //     <Breadcrumbs breadcrumbs={populateBreadcrumbs(product)} />
    //   </div>
    //   <div className="container">
    //     <div className="product-page__product">
    //       <script className="structured-data-list" type="application/ld+json">
    //         {structuredData(product)}
    //       </script>
    //       <Media query={{ maxWidth: smallScreen }}>
    //         {matches =>
    //           matches ? (
    //             <>
    //               <GalleryCarousel images={getImages()} />
    //               <div className="product-page__product__info">
    //                 {addToCartSection}
    //               </div>
    //             </>
    //           ) : (
    //             <>
    //               <div
    //                 className="product-page__product__gallery"
    //                 ref={productGallery}
    //               >
    //                 <ProductGallery images={getImages()} />
    //               </div>
    //               <div className="product-page__product__info">
    //                 <div
    //                   className={classNames(
    //                     "product-page__product__info--fixed"
    //                   )}
    //                 >
    //                   {addToCartSection}
    //                 </div>
    //               </div>
    //             </>
    //           )
    //         }
    //       </Media>
    //     </div>
    //   </div>
    //   <div className="container">
    //     <div className="product-page__product__description">
    //       <ProductDescription
    //         descriptionJson={product.descriptionJson}
    //         attributes={product.attributes}
    //       />
    //     </div>
    //   </div>
    //   <OtherProducts products={product.category.products.edges} category={product.category.name}/>
    // </div>
    React.createElement(React.Fragment, null, React.createElement("div", {
      className: "site-wrapper site-layout--default"
    }, React.createElement("main", {
      className: "site-content site-content--product-single",
      id: "wrapper"
    }, React.createElement("div", {
      className: "site-content__inner"
    }, React.createElement("div", {
      className: "site-content__holder"
    }, React.createElement("article", {
      className: "product product--single"
    }, React.createElement("div", {
      className: "product__thumbnail slick-slider"
    }, React.createElement(Slider, settings, getImages().map(function (x, i) {
      return React.createElement("img", {
        key: i,
        className: "selector",
        src: x.url,
        alt: x.id
      });
    }))), React.createElement("div", {
      className: "product__header"
    }, React.createElement("div", {
      className: "container"
    }, React.createElement(Breadcrumbs, {
      breadcrumbs: populateBreadcrumbs(product)
    })), React.createElement("ul", {
      className: "product__cats list-unstyled"
    }, React.createElement("li", {
      className: "product__cats-item color-warning"
    }, React.createElement("a", {
      href: "#"
    }, product === null || product === void 0 ? void 0 : (_product$category2 = product.category) === null || _product$category2 === void 0 ? void 0 : _product$category2.name))), React.createElement("h2", {
      className: "product__title h3"
    }, product === null || product === void 0 ? void 0 : product.name), React.createElement("ul", {
      className: "product__meta list-unstyled"
    }, React.createElement("li", {
      className: "product__meta-item product__price"
    }, product === null || product === void 0 ? void 0 : (_product$pricing2 = product.pricing) === null || _product$pricing2 === void 0 ? void 0 : (_product$pricing2$pri = _product$pricing2.priceRange) === null || _product$pricing2$pri === void 0 ? void 0 : (_product$pricing2$pri2 = _product$pricing2$pri.start) === null || _product$pricing2$pri2 === void 0 ? void 0 : (_product$pricing2$pri3 = _product$pricing2$pri2.gross) === null || _product$pricing2$pri3 === void 0 ? void 0 : (_product$pricing2$pri4 = _product$pricing2$pri3.amount) === null || _product$pricing2$pri4 === void 0 ? void 0 : _product$pricing2$pri4.toLocaleString('fr-FR'), " \u20AC"), React.createElement("li", {
      className: "product__meta-item product__ratings"
    }))), mainAttributes[category] ? React.createElement(React.Fragment, null, React.createElement("ul", {
      className: "product__options list-unstyled"
    }, React.createElement("li", {
      className: "product__option"
    }, getSvg(mainAttributes[category][0][0].name), React.createElement("span", {
      className: "product__option-label"
    }, mainAttributes[category][0][0].name), React.createElement("p", {
      className: "product__option-value"
    }, getValueAtrribute(product, mainAttributes[category][0][0].name), React.createElement("span", null, " ", mainAttributes[category][0][0].unite, " "))), React.createElement("li", {
      className: "product__option"
    }, getSvg(mainAttributes[category][0][1].name), React.createElement("span", {
      className: "product__option-label"
    }, mainAttributes[category][0][1].name), React.createElement("p", {
      className: "product__option-value"
    }, getValueAtrribute(product, mainAttributes[category][0][1].name), React.createElement("span", null, " ", mainAttributes[category][0][1].unite, " "))), React.createElement("li", {
      className: "product__option"
    }, getSvg(mainAttributes[category][0][2].name), React.createElement("span", {
      className: "product__option-label"
    }, mainAttributes[category][0][2].name), React.createElement("p", {
      className: "product__option-value"
    }, getValueAtrribute(product, mainAttributes[category][0][2].name), React.createElement("span", null, " ", mainAttributes[category][0][2].unite, " ")))), React.createElement("ul", {
      className: "product__options list-unstyled"
    }, React.createElement("li", {
      className: "product__option"
    }, getSvg(mainAttributes[category][1][0].name), React.createElement("span", {
      className: "product__option-label"
    }, mainAttributes[category][1][0].name), React.createElement("p", {
      className: "product__option-value"
    }, getValueAtrribute(product, mainAttributes[category][1][0].name), React.createElement("span", null, " ", mainAttributes[category][1][0].unite, " "))), React.createElement("li", {
      className: "product__option"
    }, getSvg(mainAttributes[category][1][1].name), React.createElement("span", {
      className: "product__option-label"
    }, mainAttributes[category][1][1].name), React.createElement("p", {
      className: "product__option-value"
    }, getValueAtrribute(product, mainAttributes[category][1][1].name), React.createElement("span", null, " ", mainAttributes[category][1][1].unite, " "))), React.createElement("li", {
      className: "product__option"
    }, getSvg(mainAttributes[category][1][2].name), React.createElement("span", {
      className: "product__option-label"
    }, mainAttributes[category][1][2].name), React.createElement("p", {
      className: "product__option-value"
    }, getValueAtrribute(product, mainAttributes[category][1][2].name), React.createElement("span", null, " ", mainAttributes[category][1][2].unite, " "))))) : null, addToCartSection, React.createElement("div", {
      className: ""
    }, React.createElement("p", {
      className: "informations-title"
    }, "Informations")), detailsAttributes().map(function (x, i) {
      return React.createElement("ul", {
        className: "product__options list-unstyled"
      }, x.map(function (y, j) {
        return React.createElement("li", {
          className: "product__option"
        }, React.createElement("span", {
          className: "product__option-label"
        }, y.attribute.name), React.createElement("p", {
          className: "product__option-value"
        }, y.values[0].name));
      }));
    }), product.descriptionJson.length > 4 ? React.createElement(React.Fragment, null, React.createElement("div", {
      className: ""
    }, React.createElement("p", {
      className: "informations-title"
    }, "Description")), React.createElement("div", {
      className: "product__excerpt"
    }, React.createElement(RichTextContent, {
      descriptionJson: product.descriptionJson
    }))) : null))))))
  );
};

export default Page;