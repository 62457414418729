import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useEffect } from "react";
import { useAuth } from "@saleor/sdk";
import { Loader } from "@components/atoms";
import { MainMenu, OverlayManager, OverlayProvider } from "../components";
import ShopProvider from "../components/ShopProvider";
import "../globalStyles/scss/index.scss";
import { Routes } from "./routes";
import Notifications from "./Notifications";

var App = function App() {
  var _useAuth = useAuth(),
      tokenRefreshing = _useAuth.tokenRefreshing,
      tokenVerifying = _useAuth.tokenVerifying;

  if (tokenRefreshing || tokenVerifying) {
    return React.createElement(Loader, null);
  }

  var _React$useState = React.useState(window.innerHeight - (window.innerWidth >= 1920 ? 100 : 72)),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      windowHeight = _React$useState2[0],
      setWindowHeight = _React$useState2[1];

  window.addEventListener('resize', function () {
    return setWindowHeight(window.innerHeight - (window.innerWidth >= 1920 ? 100 : 72));
  });
  useEffect(function () {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowHeight(window.innerHeight - (window.innerWidth >= 1920 ? 100 : 72));
    } // Add event listener


    window.addEventListener("resize", handleResize); // Call handler right away so state gets updated with initial window size

    handleResize(); // Remove event listener on cleanup

    return function () {
      return window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty array ensures that effect is only run on mount

  return React.createElement(React.Fragment, null, React.createElement(ShopProvider, null, React.createElement(OverlayProvider, null, React.createElement("div", {
    className: "d-block wrapper",
    style: {
      height: windowHeight
    }
  }, React.createElement(Routes, null)), React.createElement(MainMenu, null), React.createElement(OverlayManager, null), React.createElement(Notifications, null))));
};

export default App;