import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import "./scss/index.scss";
import classNames from "classnames";
import * as React from "react";

var Overlay = function Overlay(_ref) {
  var _classNames;

  var children = _ref.children,
      className = _ref.className,
      _ref$context = _ref.context,
      type = _ref$context.type,
      theme = _ref$context.theme,
      hide = _ref$context.hide,
      testingContext = _ref.testingContext;

  var _React$useState = React.useState(window.innerHeight - (window.innerWidth >= 1920 ? 100 : 72)),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      windowHeight = _React$useState2[0],
      setWindowHeight = _React$useState2[1];

  window.addEventListener('resize', function () {
    return setWindowHeight(window.innerHeight - (window.innerWidth >= 1920 ? 100 : 72));
  });
  React.useEffect(function () {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowHeight(window.innerHeight - (window.innerWidth >= 1920 ? 100 : 72));
    } // Add event listener


    window.addEventListener("resize", handleResize); // Call handler right away so state gets updated with initial window size

    handleResize(); // Remove event listener on cleanup

    return function () {
      return window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty array ensures that effect is only run on mount

  return React.createElement("div", {
    className: classNames("overlay", (_classNames = {}, _defineProperty(_classNames, "overlay--".concat(type), !!type), _defineProperty(_classNames, className, !!className), _classNames)),
    "data-test": testingContext,
    onClick: hide
  }, React.createElement("div", {
    className: "overlay__".concat(theme),
    onClick: function onClick(e) {
      return e.stopPropagation();
    },
    style: {
      height: windowHeight
    }
  }, children));
};

export default Overlay;