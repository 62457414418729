import _objectSpread from "@babel/runtime/helpers/objectSpread";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { FormattedMessage } from "react-intl";
import { IconButton } from "@components/atoms";
import { AttributeValuesChecklist } from "@components/molecules";
import { useHandlerWhenClickedOutside } from "@hooks";
import { commonMessages } from "@temp/intl";
import { Overlay } from "..";
import * as S from "./styles";

var checkIfAttributeIsChecked = function checkIfAttributeIsChecked(filters, value, slug) {
  if (filters.attributes && filters.attributes.hasOwnProperty(slug)) {
    if (filters.attributes[slug].find(function (filter) {
      return filter === value.slug;
    })) {
      return true;
    }

    return false;
  }

  return false;
};

export var FilterSidebar = function FilterSidebar(_ref) {
  var hide = _ref.hide,
      filters = _ref.filters,
      show = _ref.show,
      attributes = _ref.attributes,
      target = _ref.target,
      onAttributeFiltersChange = _ref.onAttributeFiltersChange;

  var _useHandlerWhenClicke = useHandlerWhenClickedOutside(function () {
    hide();
  }),
      setElementRef = _useHandlerWhenClicke.setElementRef;

  var _React$useState = React.useState(window.innerHeight - (window.innerWidth >= 1920 ? 100 : 72)),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      windowHeight = _React$useState2[0],
      setWindowHeight = _React$useState2[1];

  window.addEventListener('resize', function () {
    return setWindowHeight(window.innerHeight - (window.innerWidth >= 1920 ? 100 : 72));
  });
  React.useEffect(function () {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowHeight(window.innerHeight - (window.innerWidth >= 1920 ? 100 : 72));
    } // Add event listener


    window.addEventListener("resize", handleResize); // Call handler right away so state gets updated with initial window size

    handleResize(); // Remove event listener on cleanup

    return function () {
      return window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty array ensures that effect is only run on mount

  return React.createElement(Overlay, {
    duration: 0,
    position: "left",
    show: show,
    hide: hide,
    transparent: true,
    target: target
  }, React.createElement(S.Wrapper, {
    ref: setElementRef(),
    "data-test": "filterSidebar",
    style: {
      height: windowHeight
    }
  }, React.createElement(S.Header, null, React.createElement("span", null, React.createElement(FormattedMessage, commonMessages.filterHeader)), React.createElement(IconButton, {
    testingContext: "hideFilters",
    onClick: hide,
    name: "x",
    size: 18,
    color: "000"
  })), React.createElement("div", {
    style: {
      height: windowHeight
    }
  }, attributes.map(function (_ref2) {
    var id = _ref2.id,
        name = _ref2.name,
        slug = _ref2.slug,
        values = _ref2.values;
    return React.createElement(AttributeValuesChecklist, {
      key: id,
      title: name,
      name: slug,
      values: values.map(function (value) {
        return _objectSpread({}, value, {
          selected: checkIfAttributeIsChecked(filters, value, slug)
        });
      }),
      valuesShowLimit: true,
      onValueClick: function onValueClick(value) {
        return onAttributeFiltersChange(slug, value.slug);
      }
    });
  }))));
};