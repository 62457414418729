import * as React from "react"; // eslint-disable-next-line import/no-extraneous-dependencies

import { Canvas, useFrame, useLoader } from "@react-three/fiber";
import { Environment, OrbitControls } from "@react-three/drei"; // eslint-disable-next-line import/no-extraneous-dependencies

import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

var Model = function Model() {
  var gltf = useLoader(GLTFLoader, "/assets/3d/mm/mm.gltf");
  var myMesh = React.useRef();
  useFrame(function (_ref) {
    var clock = _ref.clock;
    var a = clock.getElapsedTime();

    if (myMesh && myMesh.current) {
      try {
        // @ts-ignore
        myMesh.current.rotation.x = a / 2; // @ts-ignore

        myMesh.current.rotation.y = a / 2; // @ts-ignore

        myMesh.current.rotation.z = a / 2;
      } catch (e) {
        return null;
      }
    }
  });
  return React.createElement("mesh", {
    ref: myMesh
  }, React.createElement("primitive", {
    object: gltf.scene,
    scale: 100
  }));
};

export var RenderLogo = function RenderLogo() {
  return React.createElement("div", {
    style: {
      marginTop: "-150px",
      height: "100%",
      width: "100%"
    }
  }, React.createElement(Canvas, null, React.createElement(React.Suspense, {
    fallback: null
  }, React.createElement(Model, null), React.createElement(OrbitControls, {
    enableZoom: false
  }), React.createElement(Environment, {
    preset: "sunset"
  }))));
};