import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import "./index.scss";
import React, { useEffect } from "react";
import * as opt from "./graphOptions";
import EChartsReact from "echarts-for-react";

var Data = function Data() {
  // Infos
  var _React$useState = React.useState(null // JSON.parse(sessionStorage.getItem("dataBTC"))
  ),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      dataBTC = _React$useState2[0],
      setDataBTC = _React$useState2[1];

  var _React$useState3 = React.useState(null // JSON.parse(sessionStorage.getItem("dataETH"))
  ),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      dataETH = _React$useState4[0],
      setDataETH = _React$useState4[1]; // Revenues


  var _React$useState5 = React.useState(null // JSON.parse(sessionStorage.getItem("earnBTC"))
  ),
      _React$useState6 = _slicedToArray(_React$useState5, 2),
      earnBTC = _React$useState6[0],
      setEarnBTC = _React$useState6[1];

  var _React$useState7 = React.useState(null // JSON.parse(sessionStorage.getItem("earnETH"))
  ),
      _React$useState8 = _slicedToArray(_React$useState7, 2),
      earnETH = _React$useState8[0],
      setEarnETH = _React$useState8[1]; // Difficulté


  var _React$useState9 = React.useState(null // JSON.parse(sessionStorage.getItem("diffBTC"))
  ),
      _React$useState10 = _slicedToArray(_React$useState9, 2),
      diffBTC = _React$useState10[0],
      setDiffBTC = _React$useState10[1];

  var _React$useState11 = React.useState(null // JSON.parse(sessionStorage.getItem("diffETH"))
  ),
      _React$useState12 = _slicedToArray(_React$useState11, 2),
      diffETH = _React$useState12[0],
      setDiffETH = _React$useState12[1]; // Hashrate


  var _React$useState13 = React.useState(null // JSON.parse(sessionStorage.getItem("hashBTC"))
  ),
      _React$useState14 = _slicedToArray(_React$useState13, 2),
      hashBTC = _React$useState14[0],
      setHashBTC = _React$useState14[1];

  var _React$useState15 = React.useState(null // JSON.parse(sessionStorage.getItem("hashETH"))
  ),
      _React$useState16 = _slicedToArray(_React$useState15, 2),
      hashETH = _React$useState16[0],
      setHashETH = _React$useState16[1]; // Price


  var _React$useState17 = React.useState(JSON.parse(sessionStorage.getItem("priceBTC"))),
      _React$useState18 = _slicedToArray(_React$useState17, 2),
      priceBTC = _React$useState18[0],
      setPriceBTC = _React$useState18[1];

  var _React$useState19 = React.useState(JSON.parse(sessionStorage.getItem("priceETH"))),
      _React$useState20 = _slicedToArray(_React$useState19, 2),
      priceETH = _React$useState20[0],
      setPriceETH = _React$useState20[1];

  useEffect(function () {
    Promise.all([// INFO
    earnBTC ? Promise.resolve(earnBTC) : fetch("https://datas.miningmarket.fr/https://whattomine.com/coins/1-btc-sha-256/history?cost=0.0&fee=0.0&format=json&hr=1000.0&p=0").then(function (res) {
      return res.json();
    }).then(function (res) {
      return setEarnBTC(res);
    }).catch(function (_) {
      return setEarnBTC(null);
    }), earnETH ? Promise.resolve(earnETH) : fetch("https://datas.miningmarket.fr/https://whattomine.com/coins/151-eth-ethash/history?cost=0.0&fee=0.0&format=json&hr=1&p=0").then(function (res) {
      return res.json();
    }).then(function (res) {
      return setEarnETH(res);
    }).catch(function (_) {
      return setEarnETH(null);
    }), // DATA
    dataETH ? Promise.resolve(dataETH) : fetch("https://datas.miningmarket.fr/https://whattomine.com/coins.json").then(function (res) {
      return res.json();
    }).then(function (res) {
      return setDataETH(res.coins["Ethereum"]);
    }).catch(function (_) {
      return setDataETH(null);
    }), dataBTC ? Promise.resolve(dataBTC) : fetch("https://datas.miningmarket.fr/https://whattomine.com/asic.json").then(function (res) {
      return res.json();
    }).then(function (res) {
      return setDataBTC(res.coins["Bitcoin"]);
    }).catch(function (err) {
      return setDataBTC(null);
    }), // HASHRATE
    hashBTC ? Promise.resolve(hashBTC) : fetch("https://datas.miningmarket.fr/https://api.blockchain.info/charts/hash-rate?daysAverageString=7D&timespan=1year&sampled=true&metadata=false&cors=true&format=json").then(function (res) {
      return res.json();
    }).then(function (res) {
      return setHashBTC(res.values);
    }).catch(function (_) {
      return setHashBTC(null);
    }), hashETH ? Promise.resolve(hashETH) : fetch("https://datas.miningmarket.fr/https://ycharts.com/charts/fund_data.json?annotations=&annualizedReturns=false&calcs=&chartType=interactive&chartView=&correlations=&dateSelection=range&displayDateRange=false&displayTicker=false&endDate=&format=real&legendOnChart=false&note=&partner=basic_2000&quoteLegend=false&recessions=false&scaleType=linear&securities=id%3AI%3AENHR%2Cinclude%3Atrue%2C%2C&securityGroup=&securitylistName=&securitylistSecurityId=&source=false&splitType=single&startDate=&title=&units=false&useCustomColors=false&useEstimates=false&zoom=1&redesign=true&chartCreator=&maxPoints=772").then(function (res) {
      return res.json();
    }).then(function (res) {
      return setHashETH(res.chart_data[0][0].raw_data);
    }).catch(function (_) {
      return setHashETH(null);
    }), // DIFFICULTE
    diffBTC ? Promise.resolve(diffBTC) : fetch("https://api.blockchain.info/charts/difficulty?timespan=1year&sampled=true&metadata=false&cors=true&format=json").then(function (res) {
      return res.json();
    }).then(function (res) {
      return setDiffBTC(res.values);
    }).catch(function (_) {
      return setDiffBTC(null);
    }), diffETH ? Promise.resolve(diffETH) : fetch("https://datas.miningmarket.fr/https://ycharts.com/charts/fund_data.json?annotations=&annualizedReturns=false&calcs=&chartType=interactive&chartView=&correlations=&dateSelection=range&displayDateRange=false&displayTicker=false&endDate=&format=real&legendOnChart=false&note=&partner=basic_2000&quoteLegend=false&recessions=false&scaleType=linear&securities=id%3AI%3AED%2Cinclude%3Atrue%2C%2C&securityGroup=&securitylistName=&securitylistSecurityId=&source=false&splitType=single&startDate=&title=&units=false&useCustomColors=false&useEstimates=false&zoom=ytd&redesign=true&chartCreator=&maxPoints=704").then(function (res) {
      return res.json();
    }).then(function (res) {
      return setDiffETH(res.chart_data[0][0].raw_data);
    }).catch(function (_) {
      return setDiffETH(null);
    }), // PRICE
    priceBTC ? Promise.resolve(priceBTC) : fetch("https://datas.miningmarket.fr/https://api.blockchain.info/price/index-series?base=BTC&quote=USD&start=1644706800&scale=900&cors=true").then(function (res) {
      return res.json();
    }).then(function (res) {
      return setPriceBTC(res);
    }).catch(function (_) {
      return setPriceBTC(null);
    }), priceETH ? Promise.resolve(priceETH) : fetch("https://datas.miningmarket.fr/https://api.blockchain.info/price/index-series?base=ETH&quote=USD&start=1644706800&scale=900&cors=true").then(function (res) {
      return res.json();
    }).then(function (res) {
      return setPriceETH(res);
    })]);
  }, []);
  console.log("diffBTC", diffBTC);
  console.log("diffETH", diffETH);
  return React.createElement("div", {
    className: "site-wrapper site-layout--default"
  }, React.createElement("div", {
    className: "site-content site-content--center",
    id: "wrapper"
  }, React.createElement("div", {
    className: "container-fluid"
  }, React.createElement("div", {
    className: "row"
  }, React.createElement("div", {
    className: "col-xl-6"
  }, React.createElement("div", {
    className: "data-card mb-3"
  }, React.createElement("div", {
    className: "data-card-body"
  }, React.createElement("div", {
    className: "row gx-4"
  }, React.createElement("div", {
    className: "col-lg-6"
  }, React.createElement("div", {
    className: "data-card"
  }, React.createElement("div", {
    className: "data-card-body py-2"
  }, React.createElement("div", {
    className: "d-flex align-items-center"
  }, React.createElement("img", {
    height: "80px",
    src: "/assets/img/bitcoin.svg",
    alt: "bitcoin"
  }))), React.createElement("div", {
    className: "data-card-arrow"
  }, React.createElement("div", {
    className: "data-card-arrow-top-left"
  }), React.createElement("div", {
    className: "data-card-arrow-top-right"
  }), React.createElement("div", {
    className: "data-card-arrow-bottom-left"
  }), React.createElement("div", {
    className: "data-card-arrow-bottom-right"
  })))), React.createElement("div", {
    className: "col-lg-6 mb-3 mb-lg-0"
  }, React.createElement("table", {
    className: "w-100 small mb-0 text-truncate text-white text-opacity-60"
  }, React.createElement("thead", null, React.createElement("tr", {
    className: "text-white text-opacity-75"
  }, React.createElement("th", {
    className: "w-50"
  }, "BTC INFOS"), React.createElement("th", {
    className: "w-25 text-end"
  }, "VALEUR"))), React.createElement("tbody", null, React.createElement("tr", null, React.createElement("td", null, "MARKET CAPITALIZATION"), React.createElement("td", {
    className: "text-end"
  }, " ", dataBTC === null || dataBTC === void 0 ? void 0 : dataBTC.market_cap.split('.')[0], " ")), React.createElement("tr", null, React.createElement("td", null, "NETWORK DIFICULTY"), React.createElement("td", {
    className: "text-end"
  }, ((diffBTC === null || diffBTC === void 0 ? void 0 : diffBTC.slice(-1)[0].y) / 1000000000000).toFixed(1), " T")), React.createElement("tr", null, React.createElement("td", null, "NETWORK HASHRATE"), React.createElement("td", {
    className: "text-end"
  }, ((hashBTC === null || hashBTC === void 0 ? void 0 : hashBTC.slice(-1)[0].y) / 1000000).toFixed(1), " EH/S")), React.createElement("tr", null, React.createElement("td", null, "LAST BLOCK"), React.createElement("td", {
    className: "text-end"
  }, " ", dataBTC === null || dataBTC === void 0 ? void 0 : dataBTC.last_block, " "))))))), React.createElement("div", {
    className: "data-card-arrow"
  }, React.createElement("div", {
    className: "data-card-arrow-top-left"
  }), React.createElement("div", {
    className: "data-card-arrow-top-right"
  }), React.createElement("div", {
    className: "data-card-arrow-bottom-left"
  }), React.createElement("div", {
    className: "data-card-arrow-bottom-right"
  })))), React.createElement("div", {
    className: "col-xl-6"
  }, React.createElement("div", {
    className: "data-card mb-3"
  }, React.createElement("div", {
    className: "data-card-body"
  }, React.createElement("div", {
    className: "row gx-4"
  }, React.createElement("div", {
    className: "col-lg-6 mb-3 mb-lg-0"
  }, React.createElement("table", {
    className: "w-100 small mb-0 text-truncate text-white text-opacity-60"
  }, React.createElement("thead", null, React.createElement("tr", {
    className: "text-white text-opacity-75"
  }, React.createElement("th", {
    className: "w-50"
  }, "ETH INFOS"), React.createElement("th", {
    className: "w-25 text-end"
  }, "VALEUR"))), React.createElement("tbody", null, React.createElement("tr", null, React.createElement("td", null, "MARKET CAPITALIZATION"), React.createElement("td", {
    className: "text-end"
  }, " ", dataETH === null || dataETH === void 0 ? void 0 : dataETH.market_cap.split('.')[0], " ")), React.createElement("tr", null, React.createElement("td", null, "NETWORK DIFICULTY"), React.createElement("td", {
    className: "text-end"
  }, " ", ((diffETH === null || diffETH === void 0 ? void 0 : diffETH.slice(-1)[0][1]) / 1000).toFixed(1) || '-', " P")), React.createElement("tr", null, React.createElement("td", null, "NETWORK HASHRATE"), React.createElement("td", {
    className: "text-end"
  }, " ", (hashETH === null || hashETH === void 0 ? void 0 : hashETH.slice(-1)[0][1].toFixed(1)) || '-', " TH/S ")), React.createElement("tr", null, React.createElement("td", null, "LAST BLOCK"), React.createElement("td", {
    className: "text-end"
  }, " ", dataETH === null || dataETH === void 0 ? void 0 : dataETH.last_block, " "))))), React.createElement("div", {
    className: "col-lg-6"
  }, React.createElement("div", {
    className: "data-card"
  }, React.createElement("div", {
    className: "data-card-body py-2"
  }, React.createElement("div", {
    className: "d-flex align-items-center"
  }, React.createElement("img", {
    height: "80px",
    src: "/assets/img/ethereum.svg",
    alt: "bitcoin"
  }))), React.createElement("div", {
    className: "data-card-arrow"
  }, React.createElement("div", {
    className: "data-card-arrow-top-left"
  }), React.createElement("div", {
    className: "data-card-arrow-top-right"
  }), React.createElement("div", {
    className: "data-card-arrow-bottom-left"
  }), React.createElement("div", {
    className: "data-card-arrow-bottom-right"
  })))))), React.createElement("div", {
    className: "data-card-arrow"
  }, React.createElement("div", {
    className: "data-card-arrow-top-left"
  }), React.createElement("div", {
    className: "data-card-arrow-top-right"
  }), React.createElement("div", {
    className: "data-card-arrow-bottom-left"
  }), React.createElement("div", {
    className: "data-card-arrow-bottom-right"
  })))), React.createElement("div", {
    className: "col-xl-3 col-lg-6"
  }, React.createElement("div", {
    className: "data-card mb-3"
  }, React.createElement("div", {
    className: "data-card-body"
  }, React.createElement("div", {
    className: "d-flex fw-bold small mb-3"
  }, React.createElement("span", {
    className: "flex-grow-1",
    style: {
      color: "white"
    }
  }, "BTC / USD"), React.createElement("img", {
    height: "20px",
    src: "/assets/img/btc.svg",
    alt: "btc"
  })), React.createElement("div", {
    className: "row align-items-center mb-2"
  }, React.createElement("div", {
    className: "col-7"
  }, React.createElement("h3", {
    className: "mb-0",
    style: {
      color: "white"
    }
  }, "$", ((priceBTC === null || priceBTC === void 0 ? void 0 : priceBTC.slice(-1)[0].price) / 1000).toFixed(1), "K")), React.createElement("div", {
    className: "col-5"
  }, React.createElement(EChartsReact, {
    style: {
      height: "70px",
      margin: 0,
      padding: 0
    },
    option: opt.btcMiniPrice(priceBTC)
  })))), React.createElement("div", {
    className: "data-card-arrow"
  }, React.createElement("div", {
    className: "data-card-arrow-top-left"
  }), React.createElement("div", {
    className: "data-card-arrow-top-right"
  }), React.createElement("div", {
    className: "data-card-arrow-bottom-left"
  }), React.createElement("div", {
    className: "data-card-arrow-bottom-right"
  })))), React.createElement("div", {
    className: "col-xl-3 col-lg-6"
  }, React.createElement("div", {
    className: "data-card mb-3"
  }, React.createElement("div", {
    className: "data-card-body"
  }, React.createElement("div", {
    className: "d-flex fw-bold small mb-3"
  }, React.createElement("span", {
    className: "flex-grow-1",
    style: {
      color: "white"
    }
  }, "BTC Network"), React.createElement("img", {
    height: "20px",
    src: "/assets/img/btc.svg",
    alt: "btc"
  })), React.createElement("div", {
    className: "row align-items-center mb-2"
  }, React.createElement("div", {
    className: "col-7"
  }, React.createElement("h3", {
    className: "mb-0",
    style: {
      color: "white"
    }
  }, ((diffBTC === null || diffBTC === void 0 ? void 0 : diffBTC.slice(-1)[0].y) / 1000000000000).toFixed(1), " T")), React.createElement("div", {
    className: "col-5"
  }, React.createElement(EChartsReact, {
    style: {
      height: "70px",
      margin: 0,
      padding: 0
    },
    option: opt.btcMiniNetwork(diffBTC)
  })))), React.createElement("div", {
    className: "data-card-arrow"
  }, React.createElement("div", {
    className: "data-card-arrow-top-left"
  }), React.createElement("div", {
    className: "data-card-arrow-top-right"
  }), React.createElement("div", {
    className: "data-card-arrow-bottom-left"
  }), React.createElement("div", {
    className: "data-card-arrow-bottom-right"
  })))), React.createElement("div", {
    className: "col-xl-3 col-lg-6"
  }, React.createElement("div", {
    className: "data-card mb-3"
  }, React.createElement("div", {
    className: "data-card-body"
  }, React.createElement("div", {
    className: "d-flex fw-bold small mb-3"
  }, React.createElement("span", {
    className: "flex-grow-1",
    style: {
      color: "white"
    }
  }, "ETH Network"), React.createElement("img", {
    height: "20px",
    src: "/assets/img/eth.svg",
    alt: "eth"
  })), React.createElement("div", {
    className: "row align-items-center mb-2"
  }, React.createElement("div", {
    className: "col-7"
  }, React.createElement("h3", {
    className: "mb-0",
    style: {
      color: "white"
    }
  }, ((diffETH === null || diffETH === void 0 ? void 0 : diffETH.slice(-1)[0][1]) / 1000).toFixed(1), " P")), React.createElement("div", {
    className: "col-5"
  }, React.createElement(EChartsReact, {
    style: {
      height: "70px",
      margin: 0,
      padding: 0
    },
    option: opt.ethMiniNetwork(diffETH)
  })))), React.createElement("div", {
    className: "data-card-arrow"
  }, React.createElement("div", {
    className: "data-card-arrow-top-left"
  }), React.createElement("div", {
    className: "data-card-arrow-top-right"
  }), React.createElement("div", {
    className: "data-card-arrow-bottom-left"
  }), React.createElement("div", {
    className: "data-card-arrow-bottom-right"
  })))), React.createElement("div", {
    className: "col-xl-3 col-lg-6"
  }, React.createElement("div", {
    className: "data-card mb-3"
  }, React.createElement("div", {
    className: "data-card-body"
  }, React.createElement("div", {
    className: "d-flex fw-bold small mb-3"
  }, React.createElement("span", {
    className: "flex-grow-1",
    style: {
      color: "white"
    }
  }, "ETH / USD"), React.createElement("img", {
    height: "20px",
    src: "/assets/img/eth.svg",
    alt: "eth"
  })), React.createElement("div", {
    className: "row align-items-center mb-2"
  }, React.createElement("div", {
    className: "col-7"
  }, React.createElement("h3", {
    className: "mb-0",
    style: {
      color: "white"
    }
  }, "$", ((priceETH === null || priceETH === void 0 ? void 0 : priceETH.slice(-1)[0].price) / 1000).toFixed(1), "K")), React.createElement("div", {
    className: "col-5"
  }, React.createElement(EChartsReact, {
    style: {
      height: "70px",
      margin: 0,
      padding: 0
    },
    option: opt.ethMiniPrice(priceETH)
  })))), React.createElement("div", {
    className: "data-card-arrow"
  }, React.createElement("div", {
    className: "data-card-arrow-top-left"
  }), React.createElement("div", {
    className: "data-card-arrow-top-right"
  }), React.createElement("div", {
    className: "data-card-arrow-bottom-left"
  }), React.createElement("div", {
    className: "data-card-arrow-bottom-right"
  })))), React.createElement("div", {
    className: "col-xl-6"
  }, React.createElement("div", {
    className: "data-card mb-3"
  }, React.createElement("div", {
    className: "data-card-body"
  }, React.createElement("div", {
    className: "d-flex fw-bold small mb-3"
  }, React.createElement("span", {
    className: "flex-grow-1",
    style: {
      color: "white"
    }
  }, "BTC REVENUE PAR TH/S"), React.createElement("img", {
    height: "20px",
    src: "/assets/img/bitcoin.svg",
    alt: "eth"
  })), React.createElement("div", {
    className: "ratio ratio-21x9 mb-3",
    style: {
      minHeight: "250px"
    }
  }, React.createElement(EChartsReact, {
    style: {
      height: "100%",
      minHeight: "300px"
    },
    option: opt.btcEarn(earnBTC)
  }))), React.createElement("div", {
    className: "data-card-arrow"
  }, React.createElement("div", {
    className: "data-card-arrow-top-left"
  }), React.createElement("div", {
    className: "data-card-arrow-top-right"
  }), React.createElement("div", {
    className: "data-card-arrow-bottom-left"
  }), React.createElement("div", {
    className: "data-card-arrow-bottom-right"
  })))), React.createElement("div", {
    className: "col-xl-6"
  }, React.createElement("div", {
    className: "data-card mb-3"
  }, React.createElement("div", {
    className: "data-card-body"
  }, React.createElement("div", {
    className: "d-flex fw-bold small mb-3"
  }, React.createElement("span", {
    className: "flex-grow-1",
    style: {
      color: "white"
    }
  }, "ETH REVENUE PAR MH/S"), React.createElement("img", {
    height: "20px",
    src: "/assets/img/eth.svg",
    alt: "eth"
  })), React.createElement("div", {
    className: "ratio ratio-21x9 mb-3",
    style: {
      minHeight: "250px"
    }
  }, React.createElement(EChartsReact, {
    style: {
      height: "100%",
      minHeight: "300px"
    },
    option: opt.ethEarn(earnETH)
  }))), React.createElement("div", {
    className: "data-card-arrow"
  }, React.createElement("div", {
    className: "data-card-arrow-top-left"
  }), React.createElement("div", {
    className: "data-card-arrow-top-right"
  }), React.createElement("div", {
    className: "data-card-arrow-bottom-left"
  }), React.createElement("div", {
    className: "data-card-arrow-bottom-right"
  })))), React.createElement("div", {
    className: "col-xl-6"
  }, React.createElement("div", {
    className: "data-card mb-3"
  }, React.createElement("div", {
    className: "data-card-body"
  }, React.createElement("div", {
    className: "d-flex fw-bold small mb-3"
  }, React.createElement("span", {
    className: "flex-grow-1",
    style: {
      color: "white"
    }
  }, "BTC NETWORK HASHRATE EN EH/S"), React.createElement("img", {
    height: "20px",
    src: "/assets/img/bitcoin.svg",
    alt: "eth"
  })), React.createElement("div", {
    className: "ratio ratio-21x9 mb-3",
    style: {
      minHeight: "250px"
    }
  }, React.createElement("div", {
    id: "chart-server"
  }, React.createElement(EChartsReact, {
    style: {
      height: "100%",
      minHeight: "300px"
    },
    option: opt.btcHashrate(hashBTC)
  })))), React.createElement("div", {
    className: "data-card-arrow"
  }, React.createElement("div", {
    className: "data-card-arrow-top-left"
  }), React.createElement("div", {
    className: "data-card-arrow-top-right"
  }), React.createElement("div", {
    className: "data-card-arrow-bottom-left"
  }), React.createElement("div", {
    className: "data-card-arrow-bottom-right"
  })))), React.createElement("div", {
    className: "col-xl-6"
  }, React.createElement("div", {
    className: "data-card mb-3"
  }, React.createElement("div", {
    className: "data-card-body"
  }, React.createElement("div", {
    className: "d-flex fw-bold small mb-3"
  }, React.createElement("span", {
    className: "flex-grow-1",
    style: {
      color: "white"
    }
  }, "ETH NETWORK HASHRATE EN TH/S"), React.createElement("img", {
    height: "20px",
    src: "/assets/img/eth.svg",
    alt: "eth"
  })), React.createElement("div", {
    className: "ratio ratio-21x9 mb-3",
    style: {
      minHeight: "250px"
    }
  }, React.createElement("div", {
    id: "chart-server"
  }, React.createElement(EChartsReact, {
    style: {
      height: "100%",
      minHeight: "300px"
    },
    option: opt.ethHashrate(hashETH)
  })))), React.createElement("div", {
    className: "data-card-arrow"
  }, React.createElement("div", {
    className: "data-card-arrow-top-left"
  }), React.createElement("div", {
    className: "data-card-arrow-top-right"
  }), React.createElement("div", {
    className: "data-card-arrow-bottom-left"
  }), React.createElement("div", {
    className: "data-card-arrow-bottom-right"
  }))))))));
};

export { Data };